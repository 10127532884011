























import { Component, Vue } from "vue-property-decorator";
import providers from "@providers/provider-apps";
import apollo from "@app/plugins/apollo";

@Component({
  components: {},
})
export default class Add extends Vue {
  limited!: boolean;
  async mounted() {
    const limit = await apollo.getVehicleLimit();
    this.limited =
      this.$route.params.type === "vehicle" && limit !== null && limit <= 0;
  }
  data() {
    return {
      limited: false,
    };
  }
  get providers() {
    return providers.filter((f) => f.type === this.$route.params.type);
  }
  addProvider(name: string) {
    this.$router.replace({
      path: `/provider/${name}/new`,
      query: this.$route.query,
    });
  }
}
